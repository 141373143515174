<template>
    <div>
        <b-row v-for="(n,i) in items" :key="'n_'+i" class="news-listing-item">
            <b-col>
                <entity-full-block-listing-item
                        :title="n.title.rendered"
                        :image="getImage(n)"
                        :date="n.date"
                        :description="n.excerpt.rendered"
                        :tags="n._embedded ? n._embedded['wp:term'] ? n._embedded['wp:term'][0] : [] : []"
                        :link="'/news/'+n.slug"
                ></entity-full-block-listing-item>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import EntityFullBlockListingItem from "./EntityFullBlockListingItem";

    export default {
        name: "EntityFullBlockListing",
        components: {EntityFullBlockListingItem},
        props: ['items'],
        methods:{
          getImage(item) {
            if(!item['_embedded']['wp:featuredmedia']) {
              return null;
            } else {
              const media = item['_embedded']['wp:featuredmedia'][0]
              if(media['media_details']['sizes']['medium_large']) {
                return media['media_details']['sizes']['medium_large']["source_url"];
              } else {
                return media['source_url'] ? media['source_url'] : null
              }
            }
          }
        }
    }
</script>

<style scoped lang="scss">
    .news-listing-item {
        margin-bottom: 24px;
        
        &:last-child { margin-bottom: 0; }
    }
</style>