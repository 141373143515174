<template>
    <div>
        <b-row>
            <b-col cols="12" lg="6" v-for="(item, i) in items" :key="i" class="pt-lg-0 pt-4 entity_container">
                <entity-block-listing-item
                    :date="item.date"
                    :title="item.title"
                    :link="item.link"
                    :image="item.image"
                    :type="item.type">
                </entity-block-listing-item>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import EntityBlockListingItem from "./EntityBlockListingItem";
    export default {
        name: "EntityBlockListing",
        components: {EntityBlockListingItem},
        props: ['items']
    }
</script>

<style scoped lang="scss">
    .entity_container {
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
            &:last-child, &:nth-last-child(2) {
                margin-bottom: 0;
            }
        }
    }
</style>
