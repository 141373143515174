<template>
    <router-link :to="link" class="entity-full-block-item">
        <b-row class="d-flex flex-row-reverse">
            <b-col cols="12" lg="4" class="pl-lg-0">
                <picture>
                    <img :src="image"/>
                </picture>
            </b-col>
            <b-col cols="12" lg="8" class="entity-full-block-item-content">
                <b-row class="mb-2 mb-lg-3">
                    <b-col cols="auto" v-if="tags.length > 0">
                        <ul class="tags">
                            <li v-for="(tag, i) in tags" :key="i">
                                <span>{{ tag.name }}</span>
                            </li>
                        </ul>
                    </b-col>
                    <b-col cols="auto">
                        <span v-if="date && tags.length > 0" class="separator">|</span>
                        <span v-if="date" class="date">{{ date_from_wp(date) }}</span>
                    </b-col>
                </b-row>
                <h2 v-if="title" v-html="title"></h2>
                <div v-if="description" v-html="description"></div>
            </b-col>
        </b-row>
    </router-link>
</template>

<script>
    import { date_formater } from './../mixins'

    export default {
        name: "EntityFullBlockListingItem",
        mixins: [date_formater],
        props: {
            title: String,
            description: String,
            image: String,
            date: String,
            link: String,
            tags: {
                type: Array,
                default: function() {
                    return []
                },
            }
        }
    }
</script>

<style scoped lang="scss">
    .entity-full-block-item {
        $local-border-radius: 8px;

        display: block;
        color: $black;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: $local-border-radius;

        &-content {
            padding: 24px 48px;

            @include media-breakpoint-up(lg){ padding: 32px 80px 32px 40px; }
        }

        h2{ margin-bottom: 24px; }

        picture{
            position: relative;
            display: block;
            overflow: hidden;
            height: 100%;
            border-radius: 0 $local-border-radius $local-border-radius 0;

            // Aspect ratio 5:3
            &::after{
                display: block;
                content: "";
                width: 100%;
                height: 0;
                padding-bottom: 60%;
            }

            @include media-breakpoint-down(lg){
                border-radius: $local-border-radius $local-border-radius 0 0;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0 $local-border-radius $local-border-radius 0;
                transition: 0.3s ease;
    
                @include media-breakpoint-down(lg){
                    border-radius: $local-border-radius $local-border-radius 0 0;
                    max-height: 280px;
                }
            }
        }


        .separator{
            margin: 0 0.5rem 0 0;
            color: $gray-500;

            @include media-breakpoint-up(lg){ margin-right: 1rem; }
        }

        .date{
            color: $gray-500;
            @extend %header-6;
        }

        .tags{
            display: inline-block;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            li {
                color: $primary;
                display: inline-block;
                margin-right: 0.5rem;
                @extend %header-6;

                &:last-child{ margin-right: 0; }

                @include media-breakpoint-up(lg){ margin-right: 1rem; }
            }
        }

        &:hover, &:focus {
            outline: none;
            text-decoration: none;

            @include media-breakpoint-up(lg){
                h2{ color: $primary; }
                img{ transform: scale(1.04); }
            }
        }
    }

    .contrast .entity-full-block-item{
        .separator{ color: $gray-700; }

        .date{
            color: $gray-700;
        }
    }
</style>

<style lang="scss">
    .entity-full-block-item{
        h2 + div p{ margin-bottom: 0; }
    }
</style>