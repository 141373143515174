var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pagination"},_vm._l((Array(_vm.number_of_pages).keys()),function(i){return _c('li',{key:i,staticClass:"pagination-item"},[(i === _vm.current_page)?_c('span',[_vm._v(_vm._s(i + 1))]):(
        i < 5 ||
          i > _vm.number_of_pages - 4 ||
          (i > _vm.current_page - 2 && i < _vm.current_page + 2)
      )?_c('a',{on:{"click":function($event){return _vm.$emit('change', i)}}},[_vm._v(" "+_vm._s(i + 1)+" ")]):(
        (i === 5 && _vm.current_page > _vm.number_of_pages - 4) ||
          (i === 5 && _vm.current_page <= _vm.number_of_pages - 4) ||
          (i === _vm.number_of_pages - 4 &&
            _vm.current_page >= 4 &&
            _vm.current_page <= _vm.number_of_pages - 4)
      )?_c('span',[_vm._v("...")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }