<template>
  <ul class="pagination">
    <li
        class="pagination-item"
        v-for="i in Array(number_of_pages).keys()"
        :key="i"
    >
      <span v-if="i === current_page">{{ i + 1 }}</span>
      <a
          v-else-if="
          i < 5 ||
            i > number_of_pages - 4 ||
            (i > current_page - 2 && i < current_page + 2)
        "
          @click="$emit('change', i)"
      >
        {{ i + 1 }}
      </a>
      <span
          v-else-if="
          (i === 5 && current_page > number_of_pages - 4) ||
            (i === 5 && current_page <= number_of_pages - 4) ||
            (i === number_of_pages - 4 &&
              current_page >= 4 &&
              current_page <= number_of_pages - 4)
        "
      >...</span
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    number_of_pages: Number,
    current_page: Number,
  },
};
</script>

<style scoped lang="scss">
.pagination {
  margin-bottom: 80px;
  padding-left: 0;
  list-style: none;
  display: inline-flex;
  flex-direction: row;

  &-item {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    margin-left: 12px;

    &:empty {
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    span,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      line-height: 28px;
      border: 1px solid $gray-500;
      text-align: center;
      transition: 0.15s ease;
    }

    span,
    a:hover {
      background: $primary;
      color: $white;
      border-color: $primary;
    }

    a {
      color: $gray-500;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>