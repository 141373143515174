<template>
    <b-link v-if="type == 'landing'" :href="link" :style="$store.state.contrast ? 'background-image: linear-gradient(180deg, rgba(13, 13, 13, 0.6) 0%, rgba(12, 12, 12, 0.8) 100%), url('+image+')' : 'background-image: linear-gradient(180deg, rgba(13, 13, 13, 0.08) 0%, rgba(12, 12, 12, 0.8) 100%), url('+image+')'" class="block-listing-item">
      <div class="block-listing-item-content">
        <h3 v-if="title">{{ title }}</h3>
        <span v-if="date">{{ date }}</span>
        <button>Więcej</button>
      </div>
    </b-link>
    <b-link v-else :to="link" :style="$store.state.contrast ? 'background-image: linear-gradient(180deg, rgba(13, 13, 13, 0.6) 0%, rgba(12, 12, 12, 0.8) 100%), url('+image+')' : 'background-image: linear-gradient(180deg, rgba(13, 13, 13, 0.08) 0%, rgba(12, 12, 12, 0.8) 100%), url('+image+')'" class="block-listing-item">
        <div class="block-listing-item-content">
             <h3 v-if="title">{{ title }}</h3>
            <span v-if="date">{{ date }}</span>
            <button>Więcej</button>
        </div>
    </b-link>
</template>

<script>
    export default {
        name: "EntityBlockListingItem",
        props: {
            type: String,
            title: String,
            date: String,
            image: String,
            link: String,
        }
    }
</script>

<style scoped lang="scss">
    .block-listing-item {
        display: block;
        color: $white;
        min-height: 260px;
        position: relative;
        border-radius: 5px;
        overflow:hidden;
        z-index: 2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            z-index: 1;
            position: absolute;
            padding: 24px;
            left: 0; top: 0; right: 0; bottom: 0;
            background: rgba($primary, 0);
            transition: 0.3s ease;

            @include media-breakpoint-up(lg){
                & > *{
                    transition: 0.3s ease;
                    transform: translateY(50%);
                }

                padding: 48px;
            }

            button{
                background: none;
                border: none;
                color: $white;
                padding: 0;

                &:focus{ outline: none; }

                @include media-breakpoint-up(lg){
                    opacity: 0;
                }
            }
        }

        &:hover, &:active, &:focus{
            .block-listing-item-content{
                background: rgba($primary, 0.88);

                @include media-breakpoint-up(lg){
                    & > *{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    .contrast .block-listing-item{

    }
</style>
