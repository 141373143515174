<template>
    <header :class="{
        'header-short': variant === 'short',
        'header-normal': variant === 'normal'
    }" :style="$store.state.contrast ? 'background-image: linear-gradient(360deg, rgba(31, 31, 31, 0.8) 0%, rgba(31, 31, 31, 0.56) 100%), url('+image+')' : 'background-image: linear-gradient(360deg, rgba(31, 31, 31, 0.56) 0%, rgba(31, 31, 31, 0.16) 100%), url('+image+')'">
        <b-container> 
            <b-row class="align-items-center">
                <b-col :cols="12" :lg="variant === 'short' ? 12 : 7">
                    <h2 :class="{
                        'text-center': variant === 'short',
                    }">{{ title }}</h2>
                </b-col>
            </b-row>
            <b-row v-if="link" class="mt-5">
                <b-col :cols="12" :lg="variant === 'short' ? 12 : 7" class="d-flex justify-content-center justify-content-lg-start">
                    <b-link :href="getLink" :target="link.target">
                        <b-button :variant="$store.state.contrast ? 'light' : 'dark'">{{ link.title }}</b-button>
                    </b-link>
                </b-col>
            </b-row>
        </b-container>
    </header>
</template>

<script>
    export default {
        name: "PageHeader",
        props: {
            variant: {
                type: String,
                validator: val => ['short', 'normal'].includes(val),
                default: 'normal',
            },
            title: String,
            link: Object,
            image: String
        },
      computed:{
          getLink () {
            if(this.link.url && this.link.url.includes("http")) {
              return this.link.url
            } else {
              return process.env.VUE_APP_DOMAIN + this.link.url
            }
          }
      },
    }
</script>

<style scoped lang="scss">
    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: $white;
    }
</style>

<style lang="scss">
    header {
        &.header-short{
            min-height: 280px;

            @include media-breakpoint-up(lg){
                min-height: 480px;
            }
        }

        &.header-normal {
            padding-top: 136px;
            padding-bottom: 136px;

            @include media-breakpoint-up(lg){
                min-height: calc(100vh - 128px);
                padding-top: 160px;
                padding-bottom: calc(160px + 4.75vw);
            }
        }
    }
</style>