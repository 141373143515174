<template>
  <div>
    <page-header variant="short"
                 title="Nasze projekty"
                 :image="require('./../assets/media/header_events.png')">
    </page-header>
    <section class="projects decorated-top-white" ref="listing">
      <b-container>
        <b-row class="mb-5">
          <b-col>
            <entity-block-listing v-if="projects.length !== 0" :items="projects"></entity-block-listing>
            <div v-else class="text-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Loading Spinner"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <pagination :number_of_pages="number_of_pages" :current_page="page"
                        @change="i => { this.page = i; this.$refs.listing.scrollIntoView({ behavior: 'smooth' }) }"></pagination>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section>
      <img :src="require('./../assets/media/decorator-white.svg')"/>
    </section>

    <section v-if="news" class="news">
      <b-container>
        <b-row class="mb-5">
          <b-col class="text-center">
            <h4>Ostatnie aktualności</h4>
          </b-col>
        </b-row>
        <b-row class="my-5">
          <b-col>
            <entity-full-block-listing :items="news"></entity-full-block-listing>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="text-center">
            <b-link to="/news">
              <b-button variant="dark">Wszystkie aktualności</b-button>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <newsletter></newsletter>
  </div>
</template>

<script>
import Newsletter from "../components/blocks/Newsletter";
import EntityBlockListing from "../components/utility/EntityBlockListing";
import {project_post_mapper} from '../components/mixins'
import PageHeader from "../components/utility/PageHeader";
import EntityFullBlockListing from "../components/utility/EntityFullBlockListing";
import Pagination from "../components/utility/Pagination";

export default {
  name: "Projects",
  components: {Pagination, EntityFullBlockListing, PageHeader, EntityBlockListing, Newsletter},
  mixins: [project_post_mapper],
  data() {
    return {
      page: 0,
    }
  },
  computed: {
    number_of_pages() {
      return Math.ceil(this.allProjects.length / 8)
    },
    projects() {
      return this.allProjects.slice(this.page * 8, this.page * 8 + 8)
    },
    allProjects() {
      let items = this.$store.state.content.project
      return this.map_projects_from_post(items)
    },
    news() {
      return this.$store.state.content.news.slice(0, 3)
    },
    type() {
      return this.$route.meta.type
    },
  },
  mounted() {
    this.$store.dispatch('getAllProjects')
    this.$store.dispatch('getAllNews')
  }
}
</script>

<style scoped lang="scss">
section.projects {
  padding-top: 80px;
  padding-bottom: 80px;
}

section.news {
  padding-bottom: 80px;
  padding-top: 80px;
}

img {
  max-width: 100%;
}
</style>